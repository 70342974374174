<template>
    <v-card>
        <v-card-title>
            <span class="headline">Add Package</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <form>
                    <v-text-field
                            v-model="editedData.PackageName"
                            label="Package Name"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.PackageType"
                            label="Package Type"
                            :error-messages="PackageTypeErrors"
                            required
                            @input="$v.editedData.PackageType.$touch()"
                            @blur="$v.editedData.PackageType.$touch()"
                    ></v-text-field>
                    <v-text-field
                            v-model="editedData.PackageDescription"
                            label="Package Description"
                    ></v-text-field>
                    <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->


                    <v-btn class="mr-4" @click="submit">submit</v-btn>
                    <v-btn @click="clear">clear</v-btn>
                </form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "createFareForm",
        props: ["success"],
        computed: {
            PackageNameErrors() {
                const errors = [];
                if (!this.$v.editedData.PackageName.$dirty) return errors;
                !this.$v.editedData.PackageName.required &&
                errors.push("Package Name is required.");
                return errors;
            },
            PackageTypeErrors() {
                const errors = [];
                if (!this.$v.editedData.PackageType.$dirty) return errors;
                !this.$v.editedData.PackageType.required &&
                errors.push("Package Type is required.");
                return errors;
            }
        },
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated && val) {
                        this.updated = false;
                        this.$emit("formResponseRoom", this.returnData);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                returnData: [],
                show: false,
                updated: false,
                editedData: {
                    PackageName: "",
                    PackageType: "",

                }
            };
        },
        validations: {
            editedData: {
                PackageName: {required},
                PackageType: {required},
            }
        },
        destroyed() {
        },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            async formData() {

                this.updated = false;
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    let param = {
                        PackageName: this.editedData.PackageName,
                        PackageType: this.editedData.PackageType,
                        PackageDescription: this.editedData.PackageDescription
                    };
                    axios.post("Aviation/InsertIntoAirPackageAsync", param).then(response => {
                        if (response.data.success) {
                            this.updated = true;
                            this.editedData = {};
                            this.returnData.status = true
                            this.returnData.message = "Package Created Successfully"
                        }
                    });
                    this.submitStatus = "PENDING";
                    setTimeout(() => {
                        this.submitStatus = "OK";
                    }, 500);
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.Fare = "";
            }
        }
    };
</script>

<style scoped>
</style>